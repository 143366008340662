
import * as __star__ from '@clayui/provider';

const {

__UNSTABLE_DataClient,
Provider,
useProvider,
__esModule
} = __star__;

export {
__UNSTABLE_DataClient,
Provider,
useProvider,
__esModule
};

